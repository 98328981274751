@import 'vendors/bootstrap/functions';
@import "vendors/bootstrap/variables";

// General variable structure

// Template;
$template-name:         'Dashpro HTML';
$template-slug:         'dashpro-html';
$template-version:      '1.0';
$template-breakpoint:      960px;
$transition-duration:   .3s;
$transition-function:   ease-in-out;

// Content
$wrapper-top-padding:   35px;
$wrapper-pd-x:          30px;

// Header
$logo-width: 3.75rem; // 60px
$nav-height: 5rem; // 100px

// Page title
$page-title-height: 3.75rem; // 60px

// Sidebar
$sidebar-width:           14.0625rem; // 225px
$collapse-sidebar-width:  3.75rem; // 60px

//Right Sidebar
$right-sidebar-width: $sidebar-width;

//Widget
$widget-padding-x:            30px;
$widget-padding-y:            30px;
$widget-list-margin-bottom:   20px;

//Footer
$footer-padding-x:  30px;
$footer-padding-y:  20px;




//Colors

// stylelint-disable
$white:    #fff;
$gray-100: #f8f9fa;
$gray-200: #ddd;
$gray-300: #bbb;
$gray-400: #aaa;
$gray-500: #999;
$gray-600: #868e96;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black:    #000;

$grays: ();
$grays: map-merge((
  "100": $gray-100,
  "200": $gray-200,
  "300": $gray-300,
  "400": $gray-400,
  "500": $gray-500,
  "600": $gray-600,
  "700": $gray-700,
  "800": $gray-800,
  "900": $gray-900
), $grays);

$blue:          #0275d8;
$indigo:        #6610f2;
$purple:        #9c77ef;
$pink:          #ff6b88;
$red:           #ff3333;
$orange:        #fb9678;
$yellow:        #fed269;
$green:         #06d6a0;
$teal:          #20c997;
$cyan:          #17bff0;

$persian-blue:  #4786ff;
$cerize-red:    #fa607e;
$mustard:       #fdb44d;

$colors: ();
$colors: map-merge((
  "blue":       $blue,
  "indigo":     $indigo,
  "purple":     $purple,
  "pink":       $pink,
  "red":        $red,
  "orange":     $orange,
  "yellow":     $yellow,
  "green":      $green,
  "teal":       $teal,
  "cyan":       $cyan,
  "white":      $white,
  "gray":       $gray-600,
  "gray-dark":  $gray-800,
  "black":      $black,

  "persian-blue": $persian-blue,
  "cerize-red":   $cerize-red,
  "mustard":      $mustard,

  "facebook": 	#4b64a6,
	"twitter": 		#0dace3,
	"googleplus": #dd4b39,
	"linkedin": 	#007bb6,
	"instagram": 	#3f729b,
	"pinterest": 	#cb2027,
	"dribbble": 	#ea4c89,
	"youtube": 		#b00,
), $colors);

$primary:             #1976d2;
$primary-dark:        #1a232d;
$primary-hover:       #1968bf;
$primary-dark-hover:  #686783;
$secondary:           $gray-600;
$success:             $green;
$info:                $cyan;
$warning:             $yellow;
$danger:              $red;
$light:               $gray-100;
$dark:                $gray-800;

$theme-colors: ();
$theme-colors: map-merge((
  "primary":    $primary,
  "secondary":  $secondary,
  "success":    $success,
  "info":       $info,
  "warning":    $warning,
  "danger":     $danger,
  "light":      $light,
  "dark":       $dark
), $theme-colors);

$theme-border-color: 	#eef1f2;
$footer-color:			 	#999;
$content-color: 			#999;

$widget-bg: 					#fff;
$navbar-bg:           linear-gradient(90deg, #835c9e, #8253eb);
$footer-bg: 					#fff;
$sidebar-bg: 					#fff;
$sidebar-dark-bg: 		#1a232d;

$nav-link-color: 							#999999;
$nav-heading-link-color: 			#999999;
$nav-link-hover-color: 				lighten($primary, 37%);
$nav-sub-link-color:					#999999;
$nav-sub-link-hover-color: 		#514d6a;
$nav-icon-color: 							#cccccc;
$nav-icon-active-color:       $primary;

// Body
$body-bg:                   #f5f7fa;
$body-color:                $content-color;
$border-color:              $theme-border-color;

// Links
$link-color:              theme-color("primary");
$link-decoration:         none;
$link-hover-color:        darken($link-color, 15%);
$link-hover-decoration:   none;

// Grid columns
$grid-columns:                12;
$grid-gutter-width:           30px;

//Fonts
$secondary-font-family:			'Poppins', sans-serif;
$tertiary-font-family:			'Poppins', sans-serif;
$font-family-sans-serif:		'Poppins', "Helvetica Neue", Arial, sans-serif;
$font-family-monospace:			Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
$font-family-base:					$font-family-sans-serif;

$headings-font-family: 			  $font-family-sans-serif;
$headings-margin-bottom:      ($spacer / 2);
$headings-font-weight:        500;
$headings-line-height:        1.2;
$headings-color:              #444;
$headings-letter-spacing:     0;

$line-height-base:            1.71428571429;

$font-size-base-px: 	14px;
$font-size-base: 			0.875rem;
$font-size-lg:   			1rem; // Assumes the browser default, typically `16px`
$font-size-sm:   			.75rem;
$font-size-xs:   			.625rem;

$h1-font-size:        3rem;      // 48px
$h2-font-size:        2.625rem;  // 42px
$h3-font-size:        2.25rem;   // 36px
$h4-font-size:        1.875rem;  // 30px
$h5-font-size:        1.500rem;  // 24px
$h6-font-size:        1.25rem;   // 20px

$font-weight-normal: 			400;
$font-weight-medium: 			500;
$font-weight-semibold:		600;
$font-weight-bold: 				bold;

$text-muted:  #bbb;

// Tables
$table-border-color:        $border-color;


// Buttons
$btn-font-family:                $headings-font-family;
$btn-font-size:                  $font-size-base;
$btn-font-weight:                400;
$btn-box-shadow:                 inset 0 1px 0 rgba($white,.15), 0 1px 1px rgba($black,.075);
$btn-focus-box-shadow:           0 0 0 2px rgba($black, .125);
$btn-active-box-shadow:          inset 0 3px 5px rgba($black,.125);

$input-btn-font-size-lg:    1.125rem;
$input-btn-font-size-sm:    0.75rem;

//Table
$table-dark-bg:               $gray-900;
$table-dark-accent-bg:        rgba($white, .05);
$table-dark-hover-bg:         rgba($white, .075);
$table-dark-border-color:     lighten($gray-900, 7.5%);
$table-dark-color:            $body-bg;

// Buttons + Forms
$input-btn-padding-y:       .5625rem;
$input-btn-padding-x:       1.2em;
$input-btn-line-height:     1.57142857;

$input-btn-focus-width:       0;
$input-btn-focus-box-shadow:  0 0 0 $input-btn-focus-width $input-btn-focus-color;

$input-btn-font-size-sm:    0.75rem;
$input-btn-padding-y-sm:    .1875rem;
$input-btn-padding-x-sm:    .5rem;
$input-btn-line-height-sm:  2;

$input-btn-font-size-lg:    1.125rem;
$input-btn-padding-y-lg:    .3125rem;
$input-btn-padding-x-lg:    1.5rem;
$input-btn-line-height-lg:  3;

//Forms
$input-padding-y-sm:                   $input-btn-padding-y-sm;
$input-padding-x-sm:                   $input-btn-padding-x-sm;
$input-line-height-sm:                 $input-btn-line-height-sm;

$input-padding-y-lg:                   $input-btn-padding-y-lg;
$input-padding-x-lg:                   $input-btn-padding-x-lg;
$input-line-height-lg:                 $input-btn-line-height-lg;
$input-focus-box-shadow:               none;

$input-focus-border-color:             rgba($component-active-bg, .5);

// Cards
$card-border-color:           $theme-border-color;
