/* -------------------- TABLE OF CONTENTS --------------------
 *
 * 1) Bootstrap Styles
 * 2) Animate.css Styles 
 * 3) Base Styles
 * 4) Helper Classes 
 * 5) Components 
 *    5.1) Buttons
 *    5.2) Progress Bar
 *    5.3) Table 
 *    5.4) Cards
 *    5.5) Blog Posts 
 *    5.6) Carousel 
 *    5.7) Tabs 
 *    5.8) Accordion 
 *    5.9) Lists 
 *    5.10) Modals 
 *    5.11) Forms 
 *    5.12) Range Slider 
 *    5.13) Pagination
 *    5.14) FullCalendar 
 *    5.15) CLNDR 
 *    5.16) GMaps 
 *    5.17) Vector Maps 
 *    5.18) Inbox 
 *    5.19) Widgets 
 *    5.20) Pricing Table 
 *    5.21) Timeline 
 *    5.22) Icon Box 
 *    5.23) Media 
 *    5.24) Contacts 
 *    5.25) Chats
 *    5.26) Site Maps
 *    5.27) Ecommerce 
 *    5.28) Weather 
 *    5.29) To-Do
 *    5.30) Custom Scroll 
 *    5.31) Tooltip 
 *    5.32) Counters 
 * 6) Layouts 
 *    6.1) Content 
 *    6.2) Header 
 *    6.3) Footer 
 *    6.4) Sidebar
 *    6.5) Right Sidebar
 *    6.6) Media Queries 
 *
 * -------------------- TABLE OF CONTENTS --------------------
 */
